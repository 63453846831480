html {
  font-family: 'Avenir Next', Avenir, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: rgb(110, 121, 121);
}

.nav-header {
  height: 62px;
  padding: 0px 20px;
}

.nav-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  margin-right: 10px;

  a {
    display: inline-block;
    height: 20px;
    line-height: 20px;
  }
}

.avatar-img {
  width: 36px;
  height: 36px;
}

.dropdown-icon {
  display: inline-block;
  width: 20px;
  height: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(193, 193, 193);
  height: 60px;
  border-radius: 3px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.panel-container {
  height: calc(100vh - 115px);
}

.idm-wrap {
  height: calc(100vh - 115px);
}
.panel-detail-container {
  position: relative;
  height: calc(100vh - 187px);
}

.panel-container-higher {
  height: calc(100vh - 115px);
}

.panel-detail-container-higher {
  position: relative;
  height: calc(100vh - 115px);
}

.panel-list-higher {
  height: calc(100vh - 115px);
}
.panel-title {
  height: 60px;
  padding: 12px 15px;
  border-bottom: solid 1px #eee;
}

.panel-detail-title {
  padding: 18px 17px 18px 30px;
  background: #fafafa;
}

.panel-list {
  height: calc(100vh - 130px);
}

.panel-list-hierarchy {
  height: calc(100vh - 190px);
}

.panel-list-commission {
  height: 60px;
}

.panel-list-item {
  height: 85px;
}

.panel-active {
  background: rgb(78, 146, 235);
}

.user-table-body {
  max-height: calc(100vh - 240px);
}

.idm-table-body {
  max-height: calc(100vh - 320px);
}

.api-table-body-shorten {
  max-height: calc(100vh - 480px);
}

.default-tbody-height {
  max-height: 560px;
}

.error-wrap {
  background: #fafdfd;
  border: 1px solid #939e9d;
  padding: 12px;
  border-radius: 2px;
}

.building-img-shadow {
  box-shadow: 0px 1px 4px rgb(0 0 0 / 50%);
}

.map-shadow {
  text-shadow: 0px 0px 4px rgb(0 0 0 / 75%);
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.33));
}

input[type='number'] {
  padding-right: 12px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

.capitalize-text {
  text-transform: capitalize;
}

.tracking-wider {
  width: 520px;
  line-height: 18px;
  letter-spacing: 0;
}

.voltage-span {
  padding-top: 1px;
  padding-bottom: 1px;
}

.logo {
  position: absolute;
  left: calc(50% - 18px);
}

.expand-icon {
  width: 16px;
  height: 14px;
  left: 967px;
  top: 449px;
  border: solid 1.5px #7d7d7d;
  border-top: solid 3px #7d7d7d;
  cursor: pointer;
}
.collapse-icon {
  display: flex;
  justify-content: center;
  width: 16px;
  height: 14px;
  left: 967px;
  top: 449px;
  border: solid 1.5px #7d7d7d;
  border-top: solid 3px #7d7d7d;
  cursor: pointer;
  span {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: #7d7d7d;
  }
}
.hierarchy-view {
  flex: 1;
  height: calc(100vh - 130px);
}
.card-tab {
  width: 200px;
  height: 40px;
  line-height: 40px;
  flex-shrink: 0;
  text-align: center;
  border-radius: 6px;
}
.react-flow__handle {
  background: #34d6d1;
}
.react-flow__attribution {
  display: none;
}
.react-flow__controls {
  background: #fff;
}
.line-2 {
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.idm-wrapper {
  height: calc(100vh - 62px);
}

.idm-filter {
  height: calc(100vh - 150px);
}

.intercom-launcher,
.intercom-lightweight-app {
  display: var(--intercom-display) !important;
}

.intercom-close-button {
  position: absolute !important;
  top: 2% !important;
  right: 30px !important;
  color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 28px;
  cursor: pointer;
  z-index: 9999999999;
}
