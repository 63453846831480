@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-body-color;
  }
}

@layer components {
  .btn-base {
    @apply relative inline-flex items-center justify-center rounded-md border shadow-sm shadow-[#D4D7DD] hover:shadow-md focus:outline-none focus:ring-2  focus:ring-offset-2 focus:ring-primary-focus;
  }

  .btn-disabled {
    @apply cursor-not-allowed opacity-60 shadow-none;
  }

  .btn-primary {
    @apply text-white bg-primary border-transparent [&:not(:disabled)]:hover:bg-primary-hover [&:not(:disabled)]:active:bg-primary-active;
  }
  .btn-secondary {
    @apply text-primary [&:not(:disabled)]:hover:bg-primary [&:not(:disabled)]:hover:text-white;
  }
  .btn-warning {
    @apply text-white bg-indicator-warning [&:not(:disabled)]:hover:bg-warningHover;
  }
  .btn-default {
    @apply text-body-color bg-default border-gray-300  [&:not(:disabled)]:hover:bg-default-hover [&:not(:disabled)]:active:bg-default-active;
  }
  .btn-danger {
    @apply text-white bg-danger border-error  [&:not(:disabled)]:hover:bg-dangerHover;
  }
  .btn-group {
    .btn-base {
      @apply [&:first-child]:rounded-r-none [&:last-child]:rounded-l-none [&:not(:last-child)]:-mr-px;
    }
  }

  .btn-sm {
    @apply px-3 py-2 text-sm leading-4;
  }
  .btn-md {
    @apply py-2 text-sm;
    padding-left: 14px;
    padding-right: 14px;
    height: 36px;
    line-height: 18px;
  }
  .btn-lg {
    @apply px-4 py-2 text-base;
  }
  .btn-xl {
    @apply px-6 py-3 text-base;
  }

  .form-item-base {
    @apply mt-4 sm:grid sm:items-start sm:gap-4 sm:mt-4;
  }
}
