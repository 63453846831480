:root {
  box-sizing: border-box;
  --intercom-display: 'block';

  body {
    margin: 0;
    padding: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}
