@mixin transform($degrees) {
  -webkit-transform: #{$degrees};
  -moz-transform: #{$degrees};
  -ms-transform: #{$degrees};
  -o-transform: #{$degrees};
  transform: #{$degrees};
}

@mixin keyframes($animationName) {
  @keyframes #{$animationName} {
    @content;
  }
}

@include keyframes(dash-rotate) {
  100% {
    @include transform(rotate(360deg));
  }
}

@include keyframes(dash-dash) {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@include keyframes(verdigris-rotate) {
  from {
    @include transform(rotate(-90deg));
  }

  to {
    @include transform(rotate(270deg));
  }
}

.loading-spinner-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 auto;
}

.loading-spinner {
  position: relative;
}

.loading-spinner-inner-content {
  clip-path: url(#vdgSpinnerClipPath);
}

.loading-spinner-angle-gradient {
  height: 64px;
  width: 64px;
  position: absolute;
  top: 0;
  left: 0;
  animation: verdigris-rotate 1.5s ease infinite;
}

.loading-spinner-clip-mask {
  height: 0px;
  width: 0px;
  position: absolute;
}

.spinner-dash {
  width: 64px;
  height: 64px;
  animation: dash-rotate 2s linear infinite;
  z-index: 2;

  & .path {
    stroke: #0fc8c3;
    stroke-linecap: round;
    animation: dash-dash 1.5s ease-in-out infinite;
  }
}
