// tooltip component
@import 'rc-tooltip/assets/bootstrap.css';
@import 'reactflow/dist/style.css';

.accordion-wrap {
  .expand::before {
    transform: rotate(90deg);
  }

  .not-expand::before {
    transform: rotate(0);
  }
}

.accordion {
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer;
}

.accordion::before {
  content: '';
  border-width: 0.5rem;
  border-style: solid;
  border-color: transparent transparent transparent #6e7979;
  position: absolute;
  top: calc(50% - 0.5rem);
  left: 1rem;
  transform-origin: 0.2rem 50%;
  transition: 0.25s transform ease;
}

.infi-scroll-dropdown {
  position: relative;
}

.infi-scroll-dropdown__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 30px 8px 16px;
  cursor: pointer;
}

.infi-scroll-dropdown__header:hover {
  background-color: #e8e8e8;
}

.infi-scroll-dropdown__options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: center;
  z-index: 50;
}

.infi-scroll-dropdown__option {
  padding: 8px;
  cursor: pointer;
}

.infi-scroll-dropdown__option:hover {
  background-color: #f8f8f8;
}

.dropdown__option.selected {
  background-color: #007bff;
  color: #fff;
}

.dropdown input {
  width: 100%;
  padding: 8px 16px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
}

.react-flow__controls {
  transform: scale(1.6);
}
.react-flow__panel.bottom {
  bottom: 15px;
}

.clickable-dot {
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  right: -8px;
  cursor: 'pointer';
}

.expanded-dot {
  width: 16px;
  height: 16px;
}

.regular-dot {
  width: 11px;
  height: 11px;
  top: calc(50% + 3px);
  right: 2px;
  cursor: 'pointer';
}
.react-flow__controls-button {
  height: 15px;
}
